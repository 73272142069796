import lazyLoading from '../hooks/lazyload';

function DivImage(props) {
  const loaded = lazyLoading(props.imgSrc);

  return (
    <div style={{ backgroundImage: `url(${loaded})` }}></div>
  )
}

export default DivImage;

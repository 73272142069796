import './Credits.scss';
import contentData from '../data/generalDescription';


function Credits(props) {
  const mode = props.mode;

  const objCreditsData = contentData.data.creditsSection;

  return(
    <section className={`Credits ${props.displayCredits ? 'Show' : ''} ${props.mode ? 'lightMode' : 'darkMode' }`} id="credits">
        <button className="Credits--btn-close" onClick={props.handleCredits}>
          close
        </button>
      <div className="Credits--wrapper">

        <div className="Credits--wrapper--text">
          <div className="Credits--wrapper--text--area">
            <div className="Credits--area">
              <h1>{objCreditsData[`title_photography_${props.activeLanguage}`]}</h1>
              <ul>
                {objCreditsData.list_photographers.map((photographer, index) =>
                  <li>
                    <a 
                      href={photographer.url_handle} 
                      target="_blank" 
                      rel="noreferrer"
                    >
                      {photographer.name}
                    </a>
                  </li>
                )}
              </ul>
            </div>

            <div className="Credits--area">
              <h1>{objCreditsData[`title_developer_${props.activeLanguage}`]}</h1>
              <ul>
                {objCreditsData.list_developers.map((developer, index) =>
                  <li>
                    <a 
                      href={developer.url_handle} 
                      target="_blank" 
                      rel="noreferrer"
                    >
                      {developer.name}
                    </a>
                  </li>
                )}
              </ul>
            </div>
          </div>
      
          <p className="italic">{objCreditsData[`title_mimi_${props.activeLanguage}`]}</p>

        </div>
      

        <div className="Credits--wrapper--image">
         <img src={props.mode ? objCreditsData.img_src[0].lighMode : objCreditsData.img_src[0].darkMode } alt="Mytam in reflection" /> 
        </div>

      </div>
    </section>
  )
}

export default Credits;
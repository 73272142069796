import { useState, useEffect } from "react";

import { useSsr } from 'usehooks-ts'

export function useScroll() {
    const { isBrowser } = useSsr()

    // storing this to get the scroll direction
    const [lastScrollTop, setLastScrollTop] = useState(0);
    // the offset of the document.body
    const [bodyOffset, setBodyOffset] = useState(
        isBrowser ? document.body.getBoundingClientRect() : { top: 0, left: 0 }
    );
    // the vertical direction
    const [scrollY, setScrollY] = useState(bodyOffset.top);
    // the horizontal direction
    const [scrollX, setScrollX] = useState(bodyOffset.left);
    // scroll direction would be either up or down
    const [scrollDirection, setScrollDirection] = useState('');

    const listener = (e: Event) => {
        isBrowser && setBodyOffset(document.body.getBoundingClientRect());
        setScrollY(-bodyOffset.top);
        setScrollX(bodyOffset.left);
        setScrollDirection(lastScrollTop > -bodyOffset.top ? "down" : "up");
        setLastScrollTop(-bodyOffset.top);
    };

    useEffect(() => {
        window.addEventListener("scroll", listener);
        window.addEventListener("click", listener);
        return () => {
            window.removeEventListener("scroll", listener);
            window.removeEventListener("click", listener);
        };
    });

    return {
        scrollY,
        scrollX,
        scrollDirection
    };
}
import AnimatedCursor from "react-animated-cursor";

function CustomCursor() {
  return(
    <div className="BtnMenu link">
      <AnimatedCursor
      showSystemCursor='true'
      innerSize={0}
      outerSize={8}
      color='255, 255, 255'
      outerAlpha={0.8}
      innerScale={0.7}
      outerScale={5}
      clickables={[
        'a',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
        '.link',
      ]}
      />
    </div>
  )
}

export default CustomCursor;
import './WorkshopTherapeutic.scss';
import { useState, useEffect, useLayoutEffect, useRef} from 'react';
import { gsap } from "gsap";
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import DivImage from '../components/DivImage.js';
import contentData from '../data/generalDescription';
import {isMobile} from 'react-device-detect';
import { Link } from 'react-router-dom';



function WorkshopTherapeutic(props) {
  const ref = useRef(null);

  const sliderWrapper = useRef(null);
  const objTherapeuticData = contentData.data.workshopTherapeuticPage;
  const objWorkshopData = contentData.data.workshopOverviewSection;

  const [galleryItemWidth, setGalleryItemWidth] = useState();

  useEffect(() => {
    if (isMobile) return;
    props.windowWidth > 1279 ? setGalleryItemWidth(55) : setGalleryItemWidth(68)
  }, [props.windowWidth])
  
  useLayoutEffect(() => {
    if(!isMobile) {
      gsap.registerPlugin(ScrollTrigger);

      let proxy = {skewX: 0}
      let skewSetter = gsap.quickSetter(ref.current, "skewX", "deg")
  
      let clamp = gsap.utils.clamp(-10,10)

      let ctx = gsap.context(() => {
        let panels = gsap.utils.toArray(".gallery-item");
        gsap.to(panels, {
          x: () => (115 - (galleryItemWidth * panels.length)) + "vw",
          ease: "none",
          scrollTrigger: {
            trigger: sliderWrapper.current,
            start: "top top",
            // pin: sliderWrapper.current,
            pin: true,
            scrub: 0.1,
            end: () => "+=" +  (sliderWrapper.current.scrollWidth - window.innerWidth),

            //markers: true,
            onUpdate: (self) => {
              let skew = clamp(self.getVelocity() / 400);
  
              // if (Math.abs(skew) > Math.abs(proxy.skew)) {
                proxy.skewX = skew;
  
                gsap.to(proxy, {
                  skewX:0,
                  duration: 1,
                  ease: "power3",
                  overwrite: true,
                  onUpdate: () => skewSetter(proxy.skewX),
                })
              // }
            }
          }
        });
      });
      return () => ctx.revert();
    }
  });

  return (
    <>

    <div className={`WorkshopTherapeutic ${props.lightMode ? "lightMode": "darkMode"}`} id="test" data-scroll-orientation="horizontal" ref={sliderWrapper}>
      <div className="WorkshopTherapeutic--SlideWrapper" ref={ref}>
        <div className="WorkshopTherapeutic--SlideWrapper--Intro gallery-item" >
          <h1>{objTherapeuticData[`title_${props.activeLanguage}`]}</h1>
          <p>{objTherapeuticData[`description_${props.activeLanguage}`]}</p>
          <h2>{objTherapeuticData.quote}<span>{objTherapeuticData.quote_credits}</span></h2>
          <Link to="/"><button>{objTherapeuticData[`btn_home_${props.activeLanguage}`]}</button></Link>
        </div>
       
        <div className="WorkshopTherapeutic--SlideWrapper--Projects gallery-item layout-1">
          <div className="gallery-item-wrapper">
            <DivImage imgSrc={objTherapeuticData.allTherapeuticWorkshops[0].images[0].src}></DivImage>
            <div className="gallery-item--Description">
              <h1>{objTherapeuticData.allTherapeuticWorkshops[0][`title_${props.activeLanguage}`]}</h1>
              <p>{objTherapeuticData.allTherapeuticWorkshops[0][`subtitle_${props.activeLanguage}`]}</p>
              <a href={objTherapeuticData.allTherapeuticWorkshops[0].link_url} target="_blank" rel="noreferrer">
                <div className="gallery-item-actionCircle">&#62;</div>
              </a>
            </div>
          </div>
        </div>

        <div className="WorkshopTherapeutic--SlideWrapper--Projects gallery-item layout-2">
          <h1>{objTherapeuticData.allTherapeuticWorkshops[1][`title_${props.activeLanguage}`]}</h1>
          <div>
            <h2>{objTherapeuticData.allTherapeuticWorkshops[1][`subtitle_${props.activeLanguage}`]}</h2>
            <a href={objTherapeuticData.allTherapeuticWorkshops[1].link_url} target="_blank" rel="noreferrer">
                <div className="gallery-item-actionCircle">&#62;</div>
            </a>
          </div>
          <div>
          <DivImage imgSrc={objTherapeuticData.allTherapeuticWorkshops[1].images[0].src}></DivImage>
          </div>
          
          
        </div>

        <div className="WorkshopTherapeutic--SlideWrapper--Projects gallery-item layout-3">
          <div className="gallery-item-wrapper">
            <div>
              <DivImage imgSrc={objTherapeuticData.allTherapeuticWorkshops[2].images[0].src}></DivImage>
            
              <div>
                <div>
                  <DivImage imgSrc={objTherapeuticData.allTherapeuticWorkshops[2].images[1].src}></DivImage>
                  <p>{objTherapeuticData.allTherapeuticWorkshops[2][`subtitle_${props.activeLanguage}`]}</p>
                </div>
                <h1>{objTherapeuticData.allTherapeuticWorkshops[2][`title_${props.activeLanguage}`]}</h1>
                <a href={objTherapeuticData.allTherapeuticWorkshops[2].link_url} target="_blank" rel="noreferrer">
                <div className="gallery-item-actionCircle">&#62;</div>
            </a>
              </div>
            </div>

          </div>

        </div>

        <div className="WorkshopTherapeutic--SlideWrapper--Projects gallery-item">
          <div className="gallery-item--circle">
          {objWorkshopData[`txt_cultural_${props.activeLanguage}`]}
            <Link to="/workshops/cultural"><button>{objWorkshopData[`btn_discover_${props.activeLanguage}`]}</button></Link>
          </div>
        </div>
      </div>
      
    </div>
    </>
  );
}

export default WorkshopTherapeutic;

import {useState, useEffect} from 'react';
import './Navigation.scss';
import Logo from '../components/Logo';
import BtnMenu from '../components/BtnMenu';
import {useScroll} from './../hooks/scrollListener'
import {isMobile} from 'react-device-detect';

function Navigation(props) {
  const [scrollDown , setScrollDown] = useState(false)
  const scrollDirection = useScroll()

  const home = document.getElementById("Home");


 useEffect(() => {
   if (home === null && !isMobile) {
    setScrollDown(false)
   } else {
    (scrollDirection.scrollDirection === 'up' && scrollDirection.scrollY > 30) ? setScrollDown(true) : setScrollDown(false);
   }
  }, [scrollDirection])

  const navbar: any = {
      active: {
          visibility: "visible",
          transition: "all 0.5s"
      },
      hidden: {
          visibility: "hidden",
          transition: "all 0.5s",
          transform: "translateY(-100%)"
      }
  }


  return(
    <header className={`Navigation ${props.mode ? "lightMode": "darkMode"}`} data-scroll-section style={scrollDown === true ? navbar.hidden : navbar.active}>
      <div className="Navigation--wrapper"  >
        <Logo></Logo>
        <BtnMenu title="Menu" handleClickMenu={props.handleClickMenu}></BtnMenu>
      </div>
    </header>
  )
}

export default Navigation;
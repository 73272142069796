import './Preloader.scss';
import {useRef, useLayoutEffect, useState} from 'react';
import Logo from '../components/Logo';
import { gsap } from "gsap";

const timeline = gsap.timeline({ repeat: 0 });

function Preloader() {
  const tl = useRef(timeline);
  const preloader = useRef(null);
  const circle = useRef(null);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useLayoutEffect(() => {
    const postFix = windowWidth > windowHeight ? "vw" : "vh";

    const ctx = gsap.context(() => {
      tl.current
        .to(circle.current, {
          width: "150" + postFix,
          height: "150" + postFix,
          duration: 1
        })
        .to('.Logo--wrapper', {
          opacity: 0,
          duration: 1
        })
        .to(preloader.current, {
          opacity: 0,
          duration: 1.5
        })
        .to(preloader.current, {
          display: 'none'
        });
    }, preloader.current);

    return () => ctx.revert();
  }, []);
 


  return(
    <section 
      ref={preloader} 
      className="Preloader" 
      id="preloader"
    >
      <Logo />
      
      <div 
        ref={circle}
        className="Preloader--Circle" > 
      </div>

    </section>
  )
}

export default Preloader;
import './SomaticYoga.scss';
import contentData from '../data/generalDescription';
import { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';

function SomaticYoga(props) {
  const objSomaticData = contentData.data.somaticPage;

  return (
    <>

    <div className={`SomaticYoga ${props.lightMode ? "lightMode": "darkMode"}`}>
      <div className="SomaticYoga--wrapper">
        <div className="SomaticYoga--wrapper-description">
          <h1>{objSomaticData[`somatic_title_${props.activeLanguage}`]}</h1>
        
          <div>
            <img src={objSomaticData.image.url} /> 
            <a className="credits-overlay" href={objSomaticData.image.credit_url_handle} target="_blank" rel="noreferrer">{objSomaticData.image.credit_name}</a>
          </div>
        
          {objSomaticData[`somatic_description_${props.activeLanguage}`]}
          <Link to="/"><button>{objSomaticData[`btn_home_${props.activeLanguage}`]}</button></Link>
        </div>
       
        <div className="SomaticYoga--wrapper-image">
          <div className="SomaticYoga--image" style={{backgroundImage:`url(${objSomaticData.image.url})`}}></div>
          <a className="credits-overlay" href={objSomaticData.image.credit_url_handle} target="_blank" rel="noreferrer">{objSomaticData.image.credit_name}</a>
        </div>
  
      </div>
    </div>
    </>
  );
}

export default SomaticYoga;

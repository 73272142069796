import "./Contact.scss";
import contentData from '../data/generalDescription';


function Contact(props) {

  const objMainMenuData = contentData.data.aboutSection;

  return (
    <section className="Contact">
        <div className="Contact--wrapper special">
          <div className="Contact--wrapper--contact">
            <h1>
              {objMainMenuData[`btn_title_contact_${props.activeLanguage}`]}
            </h1>
            <div>
              <button>
                <a 
                  href={`mailto:mytam.mayo-smith@cri-paris.org?subject=${objMainMenuData[`txt_participate_header_${props.activeLanguage}`]}`} 
                >
                  {objMainMenuData[`btn_participate_${props.activeLanguage}`]}
                </a>
              </button>

              <button>
                <a 
                  href={`mailto:mytam.mayo-smith@cri-paris.org?subject=${objMainMenuData[`txt_collaborate_header_${props.activeLanguage}`]}`}
                >
                  {objMainMenuData[`btn_collaborate_${props.activeLanguage}`]}
                </a>
              </button>
            </div>

            <button>
              <a 
                href={`mailto:mytam.mayo-smith@cri-paris.org?subject=${objMainMenuData[`txt_workshop_header_${props.activeLanguage}`]}`} 
              >
                {objMainMenuData[`btn_workshop_${props.activeLanguage}`]}
              </a>
            </button>
          
            <div className="About--Event">
              <a 
                href="https://www.instagram.com/tidal.rhythms/" 
                target="_blank" 
                rel="noreferrer"
              >
                <p>
                  {objMainMenuData[`btn_events_${props.activeLanguage}`]}
                </p>
                <div className="About--Event--Circle">&#62;</div>
              </a>
            </div>
          </div>
        </div>
    </section>
  );
}

export default Contact;
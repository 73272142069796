
import { useRef, useState, useEffect } from 'react';
import './Performances.scss';
import DivImage from '../components/DivImage.js';
import performanceData from '../data/performanceData.js'


function Performances(props) {
  const language = props.activeLanguage;
 
  const sectionPerformances = useRef(null);

  const allPerformanceData = performanceData.data.performances;
  let [currentPosition, setCurrentPosition] = useState(0);
  let currentPerformance = allPerformanceData[currentPosition]; // variable index value we can reference later

  useEffect(() => {
    const allControlImages = Array.from(document.getElementsByClassName('Performances--imageControl')[0].children);
    allControlImages[0].children[0].click();
  },[]);


  const handleClickPerformances = (elem,index) => {
    [...elem.target.parentElement.parentElement.children].forEach((sib) =>
      sib.classList.remove("active")
    );
    elem.target.parentElement.classList.add("active");
    
    setCurrentPosition(index);
  }



  return(
    <section 
      ref={sectionPerformances} 
      className="Performances"  
      id="performances" 
    >
      <p>{currentPerformance[`title_${language}`]}</p>

      <div className="Performances--mediaWrapper" >
        <DivImage imgSrc={currentPerformance.coverImage}></DivImage>
        <div className="Performances--mediaWrapper--video">
          {allPerformanceData.map((performance, index) => {
            return <iframe title={performance[`title_${language}`]} className={currentPerformance.video === performance.video ? 'visible':'hidden'} key={index} src={performance.video} scrolling="no"> </iframe>
          })}
        </div>
      </div>

     <p>{currentPerformance.place}, {currentPerformance[`date_${language}`]}</p>
    
     <div className="Performances--imageControl">
       {allPerformanceData.map((performance, index) => 
          {
            return (
              <div 
                key={index}  
                onClick={((elem) => handleClickPerformances(elem,index))}
              >
                <DivImage imgSrc={performance.coverImage}></DivImage>
              </div>
            )
          }
        )}
     </div>
    </section>
  )
}

export default Performances;





import './Logo.scss';
import { Link } from 'react-router-dom';


function Logo(props) {
  return(
    <div className="Logo--wrapper">
      <Link onClick={props.handleClickLogo} to="/" className="Logo">
        <p>Mytam</p>
        <p>Mayo-Smith</p>
      </Link>
    </div>
  )
}

export default Logo;
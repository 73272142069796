import './MainMenu.scss';
import Logo from '../components/Logo';
import BtnMenu from '../components/BtnMenu';
import {gsap} from 'gsap';
import { useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import contentData from '../data/generalDescription';


function MainMenu(props) {
  const mainMenu = useRef();

  const objMainMenuData = contentData.data.mainMenu;

  const imgSrc = {
    lightMode: "https://res.cloudinary.com/dtvsqvonc/image/upload/v1681993832/Mytam/Artist/mimi_portrait_light_1.jpg",
    darkMode: "https://res.cloudinary.com/dtvsqvonc/image/upload/v1681993832/Mytam/Artist/mimi_portrait_dark_1.jpg",
  }
  const imgSrcSolo = props.mode ? imgSrc.lightMode : imgSrc.darkMode;

  
  useEffect(() => {
    mainMenu.current = gsap.timeline({ paused: true });
    const ctx = gsap.context(() => {      
      mainMenu.current
      .set(".MainMenu", {opacity:1, display:"block"},0)
      .to(".left", 0.5, {x:0, y:0},0)
      .to(".right", 0.5, {x:0, y:0},0)
      .to(".Navigation--wrapper", 0.3, {opacity:1})
      .to(".MainMenu--content--image", 0.3, {clipPath:"inset(0px 0px 0%)"})
      
      .from([".MainMenu--content--text--item",".MainMenu--content--list--item",".Navigation--content--contact",".Navigation--content--language"], {
        duration: 1,
        opacity: 0,
        y: 20,
        stagger: 0.1,
        ease: 'expo.inOut',
      }, "-=0.5")
      .to(".MainMenu--content--image--mobile", 0.2, {clipPath:"inset(0px 0px 0%)"})
   
      .reverse();
    })
    return () => {
      ctx.revert();
    };
  }, []);
  
  useEffect(() => {
    mainMenu.current.reversed(!props.open);
  }, [props.open]);



  return(
    <div 
      ref={mainMenu} 
      className={`MainMenu ${props.open ? "is-open" : "is-closed"} ${props.mode ? "lightMode" : "darkMode" }`}
    >
      <div className="MainMenu--header">
        <div className="Navigation--wrapper">
          <Logo handleClickLogo={props.handleClickMenu}></Logo>
          <BtnMenu 
            title={objMainMenuData[`btn_close_${props.activeLanguage}`]} 
            handleClickMenu={props.handleClickMenu}>
          </BtnMenu>
        </div>
      </div>
      
      <div className="MainMenu--flexbox">
        <div className="Navigation--bg left"></div>
        <div className="Navigation--bg right">
          <div className="Navigation--content--language" onClick={props.handleClickLanguage}>{props.activeLanguage === "en" ? "Français" : "English"}</div>
          <div className="Navigation--content--contact">{objMainMenuData[`btn_contact_${props.activeLanguage}`]} </div>
        </div>
        
        <div className="MainMenu--content--left">
          <div className="MainMenu--content--text">
            {objMainMenuData[`keywords_${props.activeLanguage}`].map((keyword, index) => 
              {
                return <div key={index} className="MainMenu--content--text--item">{keyword}</div>
              }
            )}
          </div>
          <div className="MainMenu--content--image">
            <img alt="Mytam dancing" src={imgSrcSolo}/> 
          </div>
        </div>
        <div className="MainMenu--content--right">
          <ul>
            <li className="MainMenu--content--list--item" >
              <HashLink 
                onClick={props.handleClickMenu} 
                to="/#performances">{objMainMenuData[`menuListItem_${props.activeLanguage}`][0]}
              </HashLink>
            </li>
            <li className="MainMenu--content--list--item">
              <Link 
                onClick={props.handleClickMenu} 
                to="/workshops/cultural"
              >
                  {objMainMenuData[`menuListItem_${props.activeLanguage}`][1]}
              </Link>
            </li>
            <li className="MainMenu--content--list--item">
              <Link 
                onClick={props.handleClickMenu} 
                to="/workshops/therapeutic"
              >
                {objMainMenuData[`menuListItem_${props.activeLanguage}`][2]}
              </Link>
            </li>
            <li className="MainMenu--content--list--item">
              <Link 
                onClick={props.handleClickMenu} 
                to="/somatic"
              >
                {objMainMenuData[`menuListItem_${props.activeLanguage}`][3]}
              </Link>
            </li>
          </ul>
          <div className="MainMenu--content--image--mobile">
            <img 
              alt="Mytam dancing" 
              src={imgSrcSolo}
            /> 
          </div>

        </div>

      </div>
 
    </div>
  )
}

export default MainMenu;
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Preloader from './sections/Preloader';
import WorkshopCulture from './pages/WorkshopCulture';
import {useRef, useState, useEffect} from 'react';
import Navigation from './sections/Navigation.js';
import ModeToggler from './components/ModeToggler.js';
import MainMenu from './sections/MainMenu.js';
import WorkshopTherapeutic from './pages/WorkshopTherapeutic';
import Trainings from './sections/Trainings.js';
import Credits from './sections/Credits.js';
import ScrollToHashElement from "./hooks/scrollToHashElement";
import CustomCursor from './components/CustomCursor';
import SomaticYoga from './pages/SomaticYoga';
import {isMobile} from 'react-device-detect';


function App() {

  const dragRoot = useRef(); // Ref for root level element 
  const dragInstance = useRef(); 
  const dragTarget = useRef();
  const [screenSize, setScreenSize] = useState(getCurrentDimension());

  const modeSwitch = useRef(null);

  const [lightMode, setLightkMode] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const [activeLanguage, setActiveLanguage] = useState("en");

  const [displayModal, setDisplayModal] = useState(false);

  const [displayCredits, setDisplayCredits] = useState(false);

  const [vh, setVh] =useState();


  let cursor;
  if (!isMobile) {
    cursor = <CustomCursor />;
  } 


  function getCurrentDimension(){
    return {
      	width: window.innerWidth,
      	height: window.innerHeight
    }
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize(getCurrentDimension())
    }
    window.addEventListener('resize', updateDimension);
    
    return(() => {
        window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize])

  useEffect(() => {
    setVh(window.innerHeight * 0.01);
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },[vh])

  function handleMenu() {
    setMenuIsOpen(prevState => !prevState);
  }

  function handleLanguage() {
    if (activeLanguage === "en") {
      setActiveLanguage("fr");
    } else {
      setActiveLanguage("en");
    }
  }

  function handleModal() {
    setDisplayModal(!displayModal);
  }

  function handleCredits() {
    setDisplayCredits(!displayCredits);
  }
  
  //Interferes with the pinned sections from GSAP
  
  // useEffect( () => {
  //   if (menuIsOpen || displayModal) {
  //     document.getElementsByTagName('body')[0].classList.add('fixed-position');
  //   } else {
  //     document.getElementsByTagName('body')[0].classList.remove('fixed-position');
  //   }
  // }, [menuIsOpen, displayModal])

  useEffect( () => {
    (
      async () => {
          const LocomotiveScroll = (await import('locomotive-scroll')).default
          const locomotiveScroll = new LocomotiveScroll();
      }
    )()
  }, [])

   useEffect( () => {
    modeSwitch.current.addEventListener('change',function(){
      if(this.checked) {
        setLightkMode(true);
      } else {
        setLightkMode(false);
      }
    });
  },[lightMode])




  return (
    <>
      <Credits 
        activeLanguage={activeLanguage} 
        mode={lightMode} 
        modal={displayCredits} 
        displayCredits={displayCredits} 
        handleCredits={() => handleCredits()}
      />

      <Trainings 
        mode={lightMode} 
        modal={displayModal} 
        displayModal={displayModal} 
        handleModal={() => handleModal()} 
        activeLanguage={activeLanguage}
      />

      <ModeToggler 
        modeSwitch={modeSwitch} 
        mode={lightMode} 
        dragRoot={dragRoot} 
        dragInstance={dragInstance} 
        dragTarget={dragTarget} 
      />

      <MainMenu 
        mode={lightMode} 
        open={menuIsOpen} 
        handleClickMenu={() => handleMenu()} 
        activeLanguage={activeLanguage} 
        handleClickLanguage={() => handleLanguage()}
      />

      <Navigation 
        mode={lightMode} 
        handleClickMenu={() => handleMenu()} 
        activeLanguage={activeLanguage}
      />

      {cursor}

      <Routes>

        <Route 
          path="/" 
          element= 
          {
            <Home 
              lightMode={lightMode} 
              handleClickLanguage={() => handleLanguage()} 
              handleModal={() => handleModal()} 
              displayModal={displayModal} 
              activeLanguage={activeLanguage}
              displayCredits={displayCredits} 
              handleCredits={() => handleCredits()}
            />
          } 
        />

        <Route 
          path="/workshops/cultural" 
          element=
          {
            <WorkshopCulture 
              lightMode={lightMode} 
              activeLanguage={activeLanguage}
              windowWidth={screenSize.width}
            />
          } 
        />

        <Route 
          path="/workshops/therapeutic"  
          element=
          {
            <WorkshopTherapeutic 
              lightMode={lightMode} 
              activeLanguage={activeLanguage} 
              windowWidth={screenSize.width}
            />
          } 
        />


        <Route 
          path="/somatic"  
          element=
          {
            <SomaticYoga 
              lightMode={lightMode} 
              activeLanguage={activeLanguage} 
              windowWidth={screenSize.width}
            />
          } 
        />

      </Routes>

      <ScrollToHashElement/>

      <Preloader />
    </>
  );
}

export default App;

import React, {useRef} from "react";
import "./Gallery.scss";
import DivImage from '../components/DivImage.js';
import aboutData from '../data/aboutData.js';


function Gallery(props) {

  const sliderWrapper = useRef(null);
  
  return (
    <section 
      ref={sliderWrapper}
      className="gallery-wrap" 
      id="about-gallery" 
    >
      <div className="gallery-item">
        <a 
          className="credits" 
          href={aboutData.data.aboutImages.darkMode[0].credit_link} 
          target="_blank" 
          rel="noreferrer"
        >
          {aboutData.data.aboutImages.darkMode[0].credit}
        </a>
        
        <DivImage imgSrc={aboutData.data.aboutImages.darkMode[0].src}></DivImage>
      </div>

      <div className="gallery-item">
        <a 
          className="credits" 
          href={aboutData.data.aboutImages.darkMode[1].credit_link} 
          target="_blank" 
          rel="noreferrer">
            {aboutData.data.aboutImages.darkMode[1].credit}
        </a>
        
        <DivImage imgSrc={aboutData.data.aboutImages.darkMode[1].src}></DivImage>
      </div>
      
      <div className="gallery-item">
        <a 
          className="credits" 
          href={aboutData.data.aboutImages.darkMode[2].credit_link} 
          target="_blank" 
          rel="noreferrer">{aboutData.data.aboutImages.darkMode[2].credit}</a>
          <DivImage imgSrc={aboutData.data.aboutImages.darkMode[2].src}></DivImage>
        </div>
        <div className="gallery-item">
          <a className="credits" href={aboutData.data.aboutImages.darkMode[3].credit_link} target="_blank" rel="noreferrer">{aboutData.data.aboutImages.darkMode[3].credit}</a>
          <DivImage imgSrc={aboutData.data.aboutImages.darkMode[3].src}></DivImage>
        </div>
    </section>
  );
}

export default Gallery;
export default {
  "success": true,
  "data": {
    "performances": [
      {
        "id": "1636156800",
        "title_en": "Dance performance for Alexia Charoud’s set",
        "title_fr": "Performance de danse pour le set d'Alexia Charoud",
        "description_en": "",
        "description_fr": "",
        "place": "La Main Collectif",
        "date_en": "November 2021",
        "date_fr": "Novembre 2021",
        "video" : "https://player.vimeo.com/video/865288827?background=1&autoplay=1&loop=1&byline=0&title=0",
        "coverImage": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1694959259/Mytam/Performances/IMG_1266.jpg",
        "flyer": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1687265941/Mytam/Performances/release_party.jpg",
        "images": [
          "https://res.cloudinary.com/dtvsqvonc/image/upload/v1687265941/Mytam/Performances/release_party.jpg",
        ],
      },
      {
        "id": "1647043200",
        "title_en": "Performance with Alexia Charoud and Luisa",
        "title_fr": "Performance avec Alexia Charoud et Luisa",
        "description_en": "",
        "description_fr": "",
        "place": "La Main Collectif",
        "date_en": "March 2022",
        "date_fr": "Mars 2022",
        "video" : "https://player.vimeo.com/video/865288162?background=1&autoplay=1&loop=1&byline=0&title=0",
        "coverImage": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1694959257/Mytam/Performances/Performance_copy.jpg",
        "flyer": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1687265941/Mytam/Performances/performance_makeup.jpg",
        "images": [
          "https://res.cloudinary.com/dtvsqvonc/image/upload/v1687265941/Mytam/Performances/performance_makeup.jpg",
        ],
      },
      {
        "id": "1654387200",
        "title_en": "Performance with Alexia Charoud and Luisa",
        "title_fr": "Performance avec Alexia Charoud et Luisa",
        "description_en": "",
        "description_fr": "",
        "place": "Random Kingdom",
        "date_en": "June 2022",
        "date_fr": "Juin 2022",
        "video" : "https://player.vimeo.com/video/865286175?background=1&autoplay=1&loop=1&byline=0&title=0",
        "coverImage": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1694959255/Mytam/Performances/kingdom-performance.jpg",
        "flyer": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1687265941/Mytam/Performances/random_kingdom.jpg",
        "images": [
          "https://res.cloudinary.com/dtvsqvonc/image/upload/v1687265941/Mytam/Performances/random_kingdom.jpg",
        ],
      }
    ]
  }
}
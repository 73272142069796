export default {
  "success": true,
  "data": {
    "aboutImages": {
        "lightMode": ["",""],
        "darkMode": [
          {
            "src": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1689261376/Mytam/Artist/P1016164.jpg",
            "credit": "Diego Bergandi",
            "credit_link": "https://www.instagram.com/3er_el/"
          },
          {
            "src": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1689261366/Mytam/Artist/DSC02188.jpg",
            "credit": "Aline Yatim",
            "credit_link": "https://www.instagram.com/alineyatim/"
          },
          {
            "src": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1689261371/Mytam/Artist/DSC02086.jpg",
            "credit": "Aline Yatim",
            "credit_link": "https://www.instagram.com/alineyatim/"
          },
          {
            "src": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1689261374/Mytam/Artist/DSC02282.jpg",
            "credit": "Aline Yatim",
            "credit_link": "https://www.instagram.com/alineyatim/"
          },
        ]
    }
  }
}

import { useLayoutEffect, useRef } from 'react';
import { gsap } from "gsap";
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {isMobile} from 'react-device-detect';
import DivImage from '../components/DivImage.js';
import './Landing.scss';
import contentData from '../data/generalDescription';


function Landing(props) {
  const mode = props.mode;
  const objMainMenuData = contentData.data.landingSection;

  const activeArray = mode ? [objMainMenuData.imgLanding_lightMode[0].src,objMainMenuData.imgLanding_lightMode[1].src] : [objMainMenuData.imgLanding_darkMode[0].src,objMainMenuData.imgLanding_darkMode[1].src];

  const imgWrapper = useRef(null);
  const sectionLanding = useRef(null);

  useLayoutEffect( () => {
    if (!isMobile) {
      gsap.registerPlugin(ScrollTrigger);

      let ctx = gsap.context(() => {

      const timeline = gsap.timeline({
        scrollTrigger: {
          trigger: sectionLanding.current,
          start: "50%",
          end: "140%",
          scrub: true,
          // markers: true
        }
      })

      timeline
        .to(imgWrapper.current, {autoAlpha:0})
        .to(imgWrapper.current,{clipPath: "inset(3%"})

      });
      return () => ctx.revert();
    }
  },[])

  const modeType = mode ? "lightMode" : "darkMode"

  return(
    <section 
      ref={sectionLanding}
      className="Landing" 
    >
      <div 
        ref={imgWrapper}
        className="Landing--imgWrapper"
      >
        <a 
          className="credits" 
          href={mode ? objMainMenuData[`imgLanding_${modeType}`][0].credit_link : objMainMenuData[`imgLanding_${modeType}`][1].credit_link} 
          target="_blank" 
          rel="noreferrer"
        >
          {mode ? objMainMenuData[`imgLanding_${modeType}`][0].credit : objMainMenuData[`imgLanding_${modeType}`][1].credit}
        </a>
        
        <DivImage imgSrc={activeArray[0]}></DivImage>
        <DivImage imgSrc={activeArray[1]}></DivImage>
      </div>
    </section>
  )
}

export default Landing;
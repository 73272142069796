import './WorkshopContainer.scss';
import { useLayoutEffect, useRef} from 'react';
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import { Link } from 'react-router-dom';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import useOnScreen from "../hooks/useOnScreen";
import {isMobile} from 'react-device-detect';
import contentData from '../data/generalDescription';
import circleSVG from '../workshopCircle.svg';
import circleSVG_fr from '../workshopCircle_fr.svg';


function WorkshopContainer(props) {
  const linkReferences = useRef([]); 

  const dragRoot = useRef(); // Ref for root level element 
  const dropArea = useRef(); 
  const dragInstances = useRef([]); 
  const dragLines = useRef([]); 
  const dragTargets = useRef([]);
  const hitTest = useRef([false,false]);

  const title = useRef(null);

  const onScreen = useOnScreen(dragRoot, 0);

  const objWorkshopData = contentData.data.workshopOverviewSection;

  function handleClickCircle(index) {
    if (!isMobile) return;
    linkReferences.current[index].click()
  }

  useLayoutEffect( () => {
    gsap.registerPlugin(ScrollTrigger);

    let ctx = gsap.context(() => {
      gsap.from(title.current, {
        scrollTrigger: {
          trigger: title.current,
          scrub: true,
          start: "0px bottom",
          end: "bottom+=400px bottom",
          // markers: true,
        },
        opacity: 0,
        ease: "power3.Out"
      })
    });
    return () => ctx.revert();
  }, [onScreen])
 

  useLayoutEffect(() => {
    if(!isMobile) {
      gsap.registerPlugin(Draggable)
        let ctx = gsap.context(() => {
          Object.entries(dragInstances.current).forEach(([key, el]) => {

            let parentPos = dropArea.current.getBoundingClientRect();
            let dragLineLeftPos = dragLines.current[0].getBoundingClientRect();
            let dragLineRightPos = dragLines.current[1].getBoundingClientRect();

            let bounds = [
              {left: dragLineLeftPos.left - parentPos.left - 30, width: dragLines.current[0].offsetWidth + 60 },
              {left: dragLineRightPos.left - parentPos.left - 30, width: dragLines.current[1].offsetWidth + 60}
            ]

            Draggable.create(el, {
              type:"x", 
              bounds: bounds[key],
              onDrag() {
                this.hitTest(dragTargets.current[key]) ? hitTest.current[key] = true : hitTest.current[key] = false;
              },
              onRelease() {
                if (hitTest.current[key]) {
                  linkReferences.current[key].click()
                } 
              }
            });
          });
      }, dragRoot);

      return () => ctx.revert();
    }
  }, []);


  return(
    <section 
      ref={dragRoot} 
      className="WorkshopContainer"
    >
      <div className="WorkshopContainer--Center"> 
        <h1 
          ref={title}
          className="WorkshopContainer--title" 
        >
          {objWorkshopData[`title_${props.activeLanguage}`]}
        </h1>
        <div className="WorkshopContainer--wrapper">
          <Link 
            to="/workshops/cultural" 
            ref={(el) => linkReferences.current.push(el)}
          ></Link>
          <Link 
            to="/workshops/therapeutic" 
            ref={(el) => linkReferences.current.push(el)}
          ></Link>
          <div className="WorkshopContainer--wrapper--circles">
            <div className="WorkshopContainer--dropArea" ref={dropArea}>
        
              <div 
                className="WorkshopContainer--slideHandle" 
                ref={(el) => dragInstances.current.push(el)} 
                data-value={objWorkshopData[`btn_cultural_${props.activeLanguage}`]}
              >
                <div></div> 
              </div>

              <div className="line left" ref={(el) => dragLines.current.push(el)}>
                <div data-value={objWorkshopData[`btn_cultural_${props.activeLanguage}`]}></div>
              </div>
              <div 
                className="WorkshopContainer--dropArea--wrapper" 
                style={{backgroundImage:`url(${props.activeLanguage === "en" ? circleSVG : circleSVG_fr})`}}
              >
                <div 
                  className="half-circle left" 
                  ref={(el) => dragTargets.current.push(el)} 
                  onClick={() => handleClickCircle(0)}
                ></div>

                <div 
                  className="half-circle right" 
                  ref={(el) => dragTargets.current.push(el)} 
                  onClick={() => handleClickCircle(1)}
                ></div>
              </div>
              <div className="line right" ref={(el) => dragLines.current.push(el)}>
                <div data-value={objWorkshopData[`btn_therapeutic_${props.activeLanguage}`]}></div>
              </div>
              <div 
                className="WorkshopContainer--slideHandle" 
                ref={(el) => dragInstances.current.push(el)} 
                data-value={objWorkshopData[`btn_therapeutic_${props.activeLanguage}`]}
              >
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WorkshopContainer;
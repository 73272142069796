import './About.scss';
import { useRef, useLayoutEffect, useEffect, useState } from "react";
import { gsap } from "gsap";
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import contentData from '../data/generalDescription';
import Collapsible from 'react-collapsible';
import {isMobile} from 'react-device-detect';


function About(props) {
  const sectionAbout = useRef(null);
  const sectionAboutWrapper = useRef(null);

  const objAboutData = contentData.data.aboutSection;
  const objmainMenuData = contentData.data.mainMenu;

  const intro_mobile = objAboutData[`introduction_full_${props.activeLanguage}`].slice(0,3);
  const intro_ext = objAboutData[`introduction_full_${props.activeLanguage}`].slice(3,5);

  const phrases = (isMobile) ? intro_mobile : objAboutData[`introduction_full_${props.activeLanguage}`];

  useLayoutEffect( () => {
    gsap.registerPlugin(ScrollTrigger);
    
    let ctx = gsap.context(() => {

    gsap.to(sectionAboutWrapper.current, {
      
      scrollTrigger: {
          trigger: sectionAboutWrapper.current,
          scrub: true,
          start: "40%",
          end: "90%",
      },
      opacity: 0,
      duration: 0.5
      // ease: "power3.Out",
  })

  });
    return () => ctx.revert();
}, [])


  return(
    <section data-scroll-section className="About" ref={sectionAbout}>
      <div className="About--wrapper" ref={sectionAboutWrapper}>
        {
          phrases.map( (phrase, index) => {
            return <AnimatedText key={index}>{phrase}</AnimatedText>
          })
        }
        {(() => {
          if (isMobile) {
            return (
              <Collapsible trigger={objAboutData[`btn_more_${props.activeLanguage}`]} triggerWhenOpen={objmainMenuData[`btn_close_${props.activeLanguage}`]}>
              <p>
                {intro_ext[0]}
              </p>
              <p>
                {intro_ext[1]}
              </p>
             
            </Collapsible>
            )
          }
        })()}
        
        <div className="About--Contact link">
          <div className="About--Contact--Circle" onClick={props.handleModal}>
          &#62;
          </div>
          <p>
            {objAboutData[`btn_training_${props.activeLanguage}`]}
          </p>
         </div>
      </div>
    </section>
  )
}

function AnimatedText({children}) {
  const text = useRef(null);

  useLayoutEffect( () => {
      gsap.registerPlugin(ScrollTrigger);
      
      let ctx = gsap.context(() => {
      gsap.from(text.current, {
        
          scrollTrigger: {
              trigger: text.current,
              scrub: true,
              start: "0px bottom",
                end: "bottom+=400px bottom",
              // markers: true,
          },
          opacity: 0,
          ease: "power3.Out",
      })

    });
      return () => ctx.revert();
  }, [])

  return <p ref={text}>{children}</p>
}

export default About;









import './Footer.scss';
import contentData from '../data/generalDescription';


function Footer(props) {
  const objFooterData = contentData.data.footerSection;


  return(
    <section className="Footer" id="footer">
      <div className="Footer--wrapper">
        <div className="Footer-item Footer-item--top">
          
          <p className="push">
            <a href="https://www.instagram.com/tidal.rhythms/" target="_blank">Instagram</a>
          </p>
          <p>
            <a href="mailto:mytam.mayo-smith@cri-paris.org" target="_blank">{objFooterData[`btn_available_${props.activeLanguage}`]}</a>
          </p>
        </div>
        <div className="Footer-item Footer-item--center">
          {objFooterData[`btn_center_${props.activeLanguage}`]}
        </div>
        <div className="Footer-item Footer-item--bottom">
          <p 
            style={{marginLeft: "70px"}} 
            onClick={props.handleClickLanguage}>
              {props.activeLanguage == "en" ? "Français" : "English"}
          </p>
          <p onClick={props.handleCredits}>Credits</p>
        </div>
      </div>
    </section>
  )
}

export default Footer;
const generalDescription = {
  "success": true,
  "data": {
    "mainMenu": 
    {
      "keywords_en": ["reconnection","feeling","expression"],
      "keywords_fr": ["reconnexion","ressentir","exprimer"],
      "menuListItem_en": ["performances","cultural workshops","therapeutic workshops","somatic yoga"],
      "menuListItem_fr": ["spectacles","ateliers culturels","ateliers thérapeutiques","somatic yoga"],
      "btn_close_en": "close",
      "btn_close_fr": "fermer",
      "btn_contact_en": "contact",
      "btn_contact_fr": "contact",
    },
    "landingSection": {
      "imgLanding_lightMode" : [
        {
          "src": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1682000236/Mytam/Artist/mimi_portrait_light_1.jpg",
          "credit": "Syrène",
          "credit_link": "https://www.instagram.com/syrene.eym/"
        },
        {
          "src": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1682000238/Mytam/Artist/mimi_portrait_light_2.jpg",
          "credit": "Syrène",
          "credit_link": "https://www.instagram.com/syrene.eym/"
        },
      ],
      "imgLanding_darkMode" : [
        {
          "src": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1681993832/Mytam/Artist/mimi_portrait_dark_1.jpg",
          "credit": "Diego bergandi",
          "credit_link": "https://www.instagram.com/3er_el/"
        },
        {
          "src": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1681993829/Mytam/Artist/mimi_portrait_dark_2.jpg",
          "credit": "Diego bergandi",
          "credit_link": "https://www.instagram.com/3er_el/"
        },
      ]
    },
    "aboutSection": 
    {
      "introduction_full_en": [
        "Dancer, workshop creator, collaborator and facilitator.",
        "My work is centered on creating shared moments where we can be present, sensitive, creative together; where we can reflect on the cultures we are part of and our ways of seeing.",
        "The co-created workshops are primarily based on movement, collective dialogue, and musical soundscapes to reflect on a variety of connected topics such as patriarchy, mental health, and ecology. I have also offered dance as therapy, for domestic violence and sexual violence survivors in an institutional framework.",
        "Dance, movement, is an integral part of the workshops. I see movement as a way to reconnect to our body, to the persons that surround us, to the life that surrounds us, and to the nature we may not always see, but can feel.",
        "Personally, dancing is a practice of decolonization. Of Vietnamese and U.S. origins, I feel the legacy of colonialism, racism, and war. Through dance, I find the space to embody, and release.",
      ],
      "introduction_full_fr": [
        "Danseuse, créatrice, collaboratrice et animatrice d’ateliers. ",
        "Mon travail est centré sur la création de moments partagés où nous pouvons être présents, sensibles, créatifs ensemble ; où nous pouvons réfléchir aux cultures dont nous faisons partie et à nos façons de voir.",
        "Les ateliers co-créés sont principalement basés sur le mouvement, le dialogue collectif et la musique pour réfléchir sur une variété de sujets liés tels que le patriarcat, la santé mentale et l'écologie. J'ai également proposé la danse comme thérapie, pour des survivantes de violences conjugales et sexuelles dans un cadre institutionnel.",
        "La danse, le mouvement, font partie intégrante des ateliers. Je vois le mouvement comme un moyen de se reconnecter à notre corps, aux personnes qui nous entourent, à la vie qui nous entoure, et à la nature que nous ne voyons pas toujours, mais que nous pouvons ressentir.",
        "Personnellement, la danse est une pratique de décolonisation. D'origine vietnamienne et américaine, je ressens l'héritage du colonialisme, du racisme et de la guerre. À travers la danse, je trouve l'espace pour incarner et libérer.",
      ],
      "btn_title_contact_en": "reach out",
      "btn_title_contact_fr": "contactez-moi",
      "btn_participate_en": "participate",
      "btn_participate_fr": "participer",
      "txt_participate_header_en": "Participate in a workshop",
      "txt_participate_header_fr": "Participer à un atelier",
      "btn_collaborate_en": "collaborate",
      "btn_collaborate_fr": "collaboration",
      "txt_collaborate_header_en": "Collaborate on a workshop ",
      "txt_collaborate_header_fr": "Collaborer à un atelier",
      "btn_workshop_en": "plan a workshop",
      "btn_workshop_fr": "organiser un atelier",
      "txt_workshop_header_en": "Plan a workshop for your organization",
      "txt_workshop_header_fr": "Organiser un atelier pour votre organisation",
      "btn_events_en": "upcoming events",
      "btn_events_fr": "prochains événements",
      "btn_training_en": "Training and practices",
      "btn_training_fr": "Formation et pratiques",
      "btn_more_en": "more",
      "btn_more_fr": "plus",
    },
    "workshopCulturePage": {
      "title_en": "cultural workshops",
      "title_fr": "ateliers culturels",
      "description_en": "Cultural workshops are open for anyone (unless stated otherwise), and occur in third-places, such as cultural centers, universities or public spaces. They explore our relationships and cultural perspectives through dance, movement, dialogue, and music. They intend to be safe spaces to express one self in a collective.",
      "description_fr": "Les ateliers culturels sont ouverts à tous (sauf si indiqué autrement) et se déroulent dans des tiers-lieux, des centres culturels, des universités ou des espaces publics. Ils explorent nos relations et points de vue culturels par la danse, le mouvement, le dialogue et la musique. Ils visent à être des espaces sûrs où l'on peut s'exprimer au sein d'un collectif.",
      "quote": "The other human being is the door for self-discovery.",
      "quote_credits": "Vincenzo Bellia, A Body Among Other Bodies, Relational Expressive Dance Movement Therapy, 2020",
      "btn_home_en": "Go To Home",
      "btn_home_fr": "Aller à l'Accueil",
      "allCultureWorkshops" : [
        {
          "title_en": "\"In Relation\"",
          "date_en": "3 editions: May, June, October 2021",
          "subtitle_en": 
          <>
            <span>
              Collaboration with 
              <a href="https://www.instagram.com/alexiachd/" target="_blank" rel="noreferrer"> @alexiachd</a>
            </span>
          </>,
          "title_fr": "\"In Relation\"",
          "date_fr": "3 reprises: Mai, Juin, Octobre 2021",
          "subtitle_fr": 
          <>
            <span>
              Collaboration with
              <a href="https://www.instagram.com/alexiachd/" target="_blank" rel="noreferrer"> @alexiachd</a>
            </span>
          </>,
          "images" : [
            {
              "src": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1693942193/Mytam/Workshops/workshop-mytam-sound.jpg",
              "credits": "Aline Yatim",
              "credit_link": "https://www.instagram.com/alineyatim/"
            }
          ],
          "description_en": 
          <>
            <p>In relation is an experimental workshop with the goal of creating a safe space for sharing on the COVID-19 pandemic, and the diverse cultural perceptions of mental health and well-being: through the mediums of conversation, sound, and movement.</p>
            <p>Our reflection rests in the territory of <i>relations</i>. What is our relationship to our body, to our social spheres, to mental health culture, after one year of the pandemic?></p>
            <p>These seemingly large questions will be embodied in conversations and movement activities that bring our mind-body to the here and now.</p>
            <p><i>In relation</i> is created by Mytam Mayo-Smith, writer and animator, and <u><a href="https://www.instagram.com/alexiachd/" target="_blank" rel="noreferrer">Alexia Charoud</a></u>, sound designer and live musician. All scripted sequences and sound tracks used in the workshop are original. It is conceived from a research project in the context of my masters: what is collective trauma and how can we heal it through somatic therapies?</p>
          </>,
         "description_fr": 
          <>
            <p>In relation est un atelier expérimental dont l'objectif est de créer un espace sûr pour un partage sur la pandémie de COVID-19 et les diverses perceptions culturelles de la santé mentale et du bien-être : par le biais de la conversation, du son et du mouvement.</p>
            <p>Notre réflexion s'inscrit dans le territoire des <i>relations</i>. Quelle est notre relation à notre corps, à nos sphères sociales, à la culture de la santé mentale, après un an de la pandémie?</p>
            <p>Ces questions apparemment vastes seront incarnées dans des conversations et des activités de mouvement qui amènent notre corps à l'ici et maintenant.</p>
            <p><i>In relation</i> est créé par Mytam Mayo-Smith, écrivaine et animatrice, et <u><a href="https://www.instagram.com/alexiachd/" target="_blank" rel="noreferrer">Alexia Charoud</a></u>conceptrice sonore et musicienne live. Toutes les séquences scénarisées et les bandes sonores utilisées dans l'atelier sont originales. Il a été conçu à partir d'un projet de recherche dans le cadre de mon master : qu'est-ce qu'un traumatisme collectif et comment pouvons-nous le guérir par des thérapies somatiques ?</p>
          </>
        },
        {
          "title_en": "\"Shaking off the Patriarchy\"",
          "date_en": "December 2021 and January 2022",
          "subtitle_en": 
          <>
            <span>
              Collaboration with
              <a href="https://www.instagram.com/alexiachd/" target="_blank" rel="noreferrer"> @alexiachd</a>
            </span>
          </>,
          "title_fr": "\"Shaking off the Patriarchy\"",
          "date_fr": "Decembre 2021 et Janvier 2022",
          "subtitle_fr": 
          <>
            <span>
              Collaboration with
              <a href="https://www.instagram.com/alexiachd/" target="_blank" rel="noreferrer"> @alexiachd</a>
            </span>
          </>,
          "images" : [
            {
              "src": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1693942194/Mytam/Workshops/workshop-mytam-shakingoff.jpg",
              "credits": "Aline Yatim",
              "credit_link": "https://www.instagram.com/alineyatim/"
            },
            {
              "src": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1693942613/Mytam/Workshops/workshop-mytam-shakingoff-2.jpg",
              "credits": "Aline Yatim",
              "credit_link": "https://www.instagram.com/alineyatim/"
            }
          ],
          "description_en": 
          <>
            <p><b>1st edition</b></p>
            <p>An expressive workshop of spoken word, dance &amp; live music to put into light emotions and views existing in a patriarchical society. Animated and conceived by Alexia &amp; Mytam, a YouthID workshop for an intergenerational public.</p>
            <p><b>2nd edition</b></p>
            <p>Through art, music &amp; movement, we explore &amp; transform emotions related to our patriarchal cultures. A YouthID workshop in EN/FR.</p>
            <p>"How can we move through a collective/personal emotion with curiosity, compassion? In the second edition of Shaking Off the Patriarchy, we invite you on a gradual process to creatively reflect and transform collective/personal emotions related to our patriarchal cultures. Art, dance movement &amp; live music will be our mediums to  "shake off" the patriarchy, creating more inner space, maybe lightness -  to freely imagine alternative cultures."</p>
          </>,
         "description_fr": 
          <>
            <p><b>1ère édition</b></p>
            <p>Un atelier expressif de parole, de danse et de musique live pour mettre en lumière les émotions et les points de vue existant dans une société patriarcale. Animé et conçu par Alexia &amp; Mytam, un atelier YouthID pour un public intergénérationnel.</p>
            <p><b>2ème édition</b></p>
            <p>A travers l'art, la musique et le mouvement, nous explorons et transformons les émotions liées à nos cultures patriarcales. Un atelier YouthID en EN/FR.</p>
            <p>"Comment traverser une émotion collective/personnelle avec curiosité et compassion ? Dans la deuxième édition de Shaking Off the Patriarchy, nous vous invitons à un processus graduel de réflexion créative et de transformation des émotions collectives/personnelles liées à nos cultures patriarcales. L'art, la danse, le mouvement et la musique live seront nos moyens pour "secouer" le patriarcat, créer plus d'espace intérieur, peut-être plus de légèreté - pour imaginer librement des cultures alternatives."</p>
          </>,
        },
        {
          "title_en": "\"Exploring Love\"",
          "date_en": "January 15 2022",
          "subtitle_en": 
          <>
            <span>
              Collaboration with
              <a href="https://www.instagram.com/alexiachd/" target="_blank" rel="noreferrer"> @alexiachd</a>
            </span>
          </>,
          "title_fr": "\"Exploring Love\"",
          "date_fr": "15 Janvier 2022",
          "subtitle_fr": 
          <>
            <span>
              Collaboration with
              <a href="https://www.instagram.com/alexiachd/" target="_blank" rel="noreferrer"> @alexiachd</a>
            </span>
          </>,
          "images" : [
            {
              "src": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1693942932/Mytam/Workshops/workshop-mytam-exploringlove.jpg",
              "credits": "Aline Yatim",
              "credit_link": "https://www.instagram.com/alineyatim/"
            },
            {
              "src": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1693942929/Mytam/Workshops/workshop-mytam-exploringlove2.jpg",
              "credits": "Aline Yatim",
              "credit_link": "https://www.instagram.com/alineyatim/"
            }
          ],
          "description_en": 
          <>
            <p>This living-room style workshop is an exploration of love, and the many sources and ways it arises in us. During this moment, we take the time to explore our ideas of love through a creative reflection, before immersing into a meditative movement sequence that introduces our group to the five aspects of self love, inspired by Dr. Gail Parker.</p>
            <p>Connecting to our body, our voice, and music, we create the possibility of connecting to the love within. The workshop ends with a beautiful live sound bath, played by Alexia.</p>
          </>,
         "description_fr": 
          <>
            <p>Cet atelier "living-room style" est une exploration de l'amour, de ses nombreuses sources et de la manière dont il se manifeste en nous. Pendant ce moment, nous prenons le temps d'explorer nos idées sur l'amour à travers une réflexion créative, avant de nous immerger dans une séquence de mouvements méditatifs qui présente à notre groupe les cinq aspects de l'amour de soi, inspirés par Dr Gail Parker.</p>
            <p>En nous connectant à notre corps, à notre voix et à la musique, nous créons la possibilité de nous connecter à l'amour qui est en nous. L'atelier se termine par un bain sonore joué par Alexia.</p>
          </>,
        },
        {
          "title_en": "Dance workshops for Collectif Bureau 240 at La Main Collectif",
          "date_en": "Paris - April 2 and 16 2022",
          "subtitle_en": 
          <>
            <span>
              Collaboration with
              <a href="https://www.instagram.com/alexiachd/" target="_blank" rel="noreferrer"> @alexiachd</a>
            </span>
          </>,
          "title_fr": "Dance workshops for Collectif Bureau 240 at La Main Collectif",
          "date_fr": ", Paris - 2 et 16 Avril 2022",
          "subtitle_fr": 
          <>
            <span>
              Collaboration with
              <a href="https://www.instagram.com/alexiachd/" target="_blank" rel="noreferrer"> @alexiachd</a>
            </span>
          </>,
          "images" : [
            {
              "src": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1694936972/Mytam/Workshops/workshop-battle-1.jpg",
              "credits": "Josselin Estève",
              "credit_link": ""
            },
            {
              "src": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1694936971/Mytam/Workshops/workshop-battle-2.jpg",
              "credits": "Josselin Estève",
              "credit_link": ""
            }
          ],
          "description_en": 
          <>
            <p>APRIL 2, "BATTLE" - Movement workshop inspired by Sabrina Belouaar's "BATTLE". With live music &amp; creations by <u><a href="https://www.instagram.com/alexiachd/" target="_blank" rel="noreferrer">Alexia Charoud</a></u></p>
            <p>APRIL 16, "MIROIRS" - Dance &amp; music workshop inspired by the work "Miroirs" by Véronique Joumard. Conceived by Alexia and Mytam, live music by Alexia, guidance by Mytam.</p>
            <p><i>"Inspired by the struggle of minorities to surface on the map of the visible, how we emerge from the invisible, break the shackles, the meshes of a society of representation and performance.</i></p>
            <p><i>Imagine a world in ruins: what can we rebuild with the debris, the shattered mirror of today's world?</i></p>
            <p><i>Starting afresh from intuition, from existence, an experiential society that hasn't yet looked at itself.</i></p>
            <p><i>Recreating ways of communicating, acting and moving, to weave a world we'll be proud to see." - written by Alexia Charoud</i></p>
          </>,
         "description_fr": 
         <>
            <p>2 Avril, "BATTLE" - Atelier de mouvement inspiré par l’œuvre "BATTLE" de Sabrina Belouaar. Avec de la musique live &amp; créations de <u><a href="https://www.instagram.com/alexiachd/" target="_blank" rel="noreferrer">Alexia Charoud</a></u></p>
            <p>16 Avril, "MIROIRS" - Atelier danse &amp; musique inspiré par l’œuvre “Miroirs” de Véronique Joumard. Conçu par Alexia et Mytam, musique live d’Alexia, guidance par Mytam</p>
            <p><i>« Inspiré par le combat des minorités à faire surface sur la carte du visible, comment on sort de l’invisible, on brise les carcans, les mailles d’une société de représentation et de performance.</i></p>
            <p><i>maginer un monde en ruines, qu’est-ce qu’on reconstruit avec les débris, les bris du miroir éclaté du monde d’aujourd’hui ?</i></p>
            <p><i>Repartir de l’intuition, de l’existence, une société d’expérience, qui ne se regarderait pas encore elle-même.</i></p>
            <p><i>Recréer des manières de communiquer, d’agir, de se mouvoir, pour tisser un monde qu’on sera fièr.e.s de voir. » - écrit par Alexia Charoud</i></p>
         </>,
        },
        {
          "title_en": "\"Reprenons notre souveraineté\" workshop",
          "date_en": "April 4, 2022",
          "subtitle_en": 
          <>
            <span>
            Collaboration with 
              <a href="https://www.instagram.com/universeluxe/" target="_blank" rel="noreferrer"> @Myriam</a>
            </span>
          </>,
          "title_fr": "\"Reprenons notre souveraineté\" workshop",
          "date_fr": "4 Avril 2022",
          "subtitle_fr": 
          <>
            <span>
              Collaboration with
              <a href="https://www.instagram.com/universeluxe/" target="_blank" rel="noreferrer"> @Myriam</a>
            </span>
          </>,
          "images" : [
            {
              "src": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1694937493/Mytam/Workshops/workshop-eclaiming-sovereignty.jpg",
              "credits": "Aline Yatim",
              "credit_link": "https://www.instagram.com/alineyatim/"
            }
          ],
          "description_en": 
          <>
            <p>Reclaiming your sovereignty is a courageous act to cut the invisible chains that are still present. Framed by the five elements - fire, water, air, earth, ether - we invite you to a dance ritual that sets in motion the un.conscious feelings linked to decolonization. We'll go through the motions, ending with a collective time to express our thoughts and/or share our experiences.</p>
          </>,
         "description_fr": 
         <>
            <p>Reprendre sa souveraineté est un act courageux pour couper les chaines invisibles, encore présente. Cadré par les cinq éléments - feu, eau, air, terre, éther - nous vous invitons à un rituel de danse qui met en mouvement les sentiments in.conscients liés à la décolonisation. Nous passerons par le mouvement et finirons par un temps de parole collectif pour exprimer une réflexion et/ou partager notre vécu.</p>
         </>,
        },
        {
          "title_en": "\"S’enraciner\" workshop",
          "date_en": "July 4, 2022",
          "subtitle_en": 
          <>
            <span>
            Collaboration with 
              <a href="https://www.instagram.com/coli.ds/" target="_blank" rel="noreferrer"> @Coline</a>
            </span>
          </>,
          "title_fr": "\"S’enraciner\" workshop",
          "date_fr": "4 Juillet 2022",
          "subtitle_fr": 
          <>
            <span>
              Collaboration with
              <a href="https://www.instagram.com/coli.ds/" target="_blank" rel="noreferrer"> @Coline</a>
            </span>
          </>,
          "images" : [
            {
              "src": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1694937492/Mytam/Workshops/workshop-rooting.jpg",
              "credits": "Aline Yatim",
              "credit_link": "https://www.instagram.com/alineyatim/"
            }
          ],
          "description_en": 
          <>
            <p>Rooting yourself in your thoughts, feelings, body and voice... to rediscover a sense of well-being and authentic belonging to the group.</p>
            <p>This workshop is a time for reflection and self-expression in a collective that allows us to address our wounds/strengths related to decolonization, with compassionate and caring intention.</p>
            <p>We'll put words to chosen questions, get moving, share, and participate in a collective song in safety and free creativity.</p>
            <p>The container is the space, our circle, the tools and agreements proposed at the beginning of the workshop.</p>
            <p>This workshop is offered to people of color only, and is designed for a group of 8 to preserve a sense of intimacy and security.</p>
          </>,
          "description_fr": 
            <>
              <p>S'enraciner dans ses pensées, ses sentiments, son corps, sa voix...pour retrouver un sentiment de bien-être et d'appartenance authentique au groupe.</p>
              <p>Cet atelier est un temps de réflexion et d'expression de soi dans un collectif qui nous permet d'aborder nos blessures / forces liées à la décolonisation, avec une intention de compassion et de bienveillance.</p>
              <p>On mettra des mots sur des questions choisies, on se mettra en mouvement, on partagera, et on participera à un chant collectif en sécurité et en créativité libre.</p>
              <p>Le contenant est l'espace, notre cercle, les outils et les accords proposés au début de l'atelier.</p>
              <p>Cet atelier est proposé uniquement aux personnes racisées et est conçu pour un groupe de 8 personnes afin de préserver un sentiment d'intimité et de sécurité.</p>
            </>,
        },
        {
          "title_en": "\"Connexions enracinées\" workshop",
          "date_en": "July 16, 2023",
          "subtitle_en": 
          <>
            <span>
            Collaboration with 
              <a href="https://www.instagram.com/cat.claww/" target="_blank" rel="noreferrer"> @Tanina</a>
            </span>
          </>,
          "title_fr": "\"Connexions enracinées\" workshop - July 16, 2023",
          "date_fr": "16 Juillet 2023",
          "subtitle_fr": 
          <>
            <span>
              Collaboration with
              <a href="https://www.instagram.com/cat.claww/" target="_blank" rel="noreferrer"> @Tanina</a>
            </span>
          </>,
          "images" : [
            {
              "src": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1694937488/Mytam/Workshops/workshop-connections-.jpg",
              "credits": "Aline Yatim",
              "credit_link": "https://www.instagram.com/alineyatim/"
            }
          ],
          "description_en": 
          <>
            <p>An afternoon to remember and reconnect with ourselves, nature and others.</p>
            <p>The aim of this workshop is to question our relationships, our bonds, and to develop our confidence and awareness in order to build a utopia together, and through movement.</p>
            <p>Through listening, writing and dancing exercises, our personal feelings will become the starting point for a collective experience, which will take the form of a ritual dance in 4 acts.</p>
            <p>This workshop is open to all those who wish to share this moment of connection in movement.</p>
          </>,
          "description_fr": 
          <>
            <p>Une après-midi pour se souvenir et se reconnecter à nous-mêmes, à la nature et aux autres.</p>
            <p>Le but de cet atelier est de questionner nos relations, nos liens et développer notre confiance et notre conscience afin de construire ensemble, et en mouvement, une utopie.</p>
            <p>Avec des exercices d’écoute, d'écriture et de danse, nos ressentis personnels deviendront le point de départ d’une expérience collective, qui prendra la forme d’une danse rituel en 4 actes.</p>
            <p>Cet atelier est en mixité, il est ouvert à toutes celles et tous ceux qui souhaitent partager ce moment d'interconnexion en mouvement.</p>
          </>,
        },
      ]
    },
    "workshopTherapeuticPage": {
      "title_en": "therapeutic workshops",
      "title_fr": "ateliers thérapeutiques",
      "description_en": "Therapeutic workshops are workshops held for a specific, identified public in an institutional context (hospital, or non-profit organizations). They are intended for a public that is supported by psychologists or doctors, attached to a formal organization. The aims of therapeutic workshops are similar to cultural workshops, yet focus more specifically on a sense of well-being and support.",
      "description_fr": "Les ateliers thérapeutiques sont créés pour un public spécifique et identifié dans un contexte institutionnel (hôpital, association dans la santé). Ils s'adressent à un public soutenu par des psychologues ou des médecins, rattachés à une organisation formelle. Les objectifs des ateliers thérapeutiques sont similaires à ceux des ateliers culturels, mais ils sont plus spécifiquement axés sur le bien-être et le soutien du groupe.",
      "quote": "It is in collectivities that we find reservoirs of hope and optimism.",
      "quote_credits": "Angela Y. Davis",
      "btn_home_en": "Go To Home",
      "btn_home_fr": "Aller à l'Accueil",
      "allTherapeuticWorkshops" : [
        {
          "title_en": "Women Safe",
          "subtitle_en": "Weekly dance workshop centered on various themes",
          "date_en": "September - December 2022",
          "title_fr": "Women Safe",
          "subtitle_fr": "Ateliers de danse hebdomadaires centrés sur divers thèmes",
          "date_fr": "Septembre - décembre 2022",
          "images" : [
            {
              "src": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1694793526/Mytam/Workshops/P1016201.jpg",
              "credits": "Aline Yatim",
              "credit_link": "https://www.instagram.com/alineyatim/"
            }
          ],
          "link_url": "https://www.women-safe.org/",
        },
        {
          "title_en": "Renforcer la confiance en soi",
          "subtitle_en": "Creating and facilitating an 8-session workshop program for Women Safe",
          "date_en": "March - June 2022",
          "title_fr": "Renforcer la confiance en soi",
          "subtitle_fr": "Création et animation d'un programme d'ateliers de 8 séances pour Women Safe",
          "date_fr": "March - July 2021",
          "images" : [
            {
              "src": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1694793004/Mytam/Workshops/women-safe-workshop.jpg",
              "credits": "Rapport d’activité Women Safe 2022",
              "credit_link": ""
            }
          ],
          "link_url": "https://www.women-safe.org/",
        },
        {
          "title_en": "Ateliers MOSHI",
          "subtitle_en": "Facilitated movement activities for the non-profit organization MOSHI, at l’Hopital André Grégoire",
          "date_en": "March - June 2022",
          "title_fr": "Ateliers MOSHI",
          "subtitle_fr": "Animatrice d’activités de mouvement-danse pour l’ONG MOSHI, à l’Hôpital André Grégoire",
          "date_fr": "mars - juillet 2021",
          "images" : [
            {
              "src": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1694937492/Mytam/Workshops/workshop-rooting.jpg",
              "credits": "Aline Yatim",
              "credit_link": "https://www.instagram.com/alineyatim/"
            },
            {
              "src": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1694937701/Mytam/Workshops/workshop-flower-close.jpg",
              "credits": "Aline Yatim",
              "credit_link": "https://www.instagram.com/alineyatim/"
            }
          ],
          "link_url": "https://www.moshi-philo.org/",
        },
      ]
    },
    "workshopOverviewSection": {
      "title_en": <>Discover <br/>Workshops<span>Drag to Scene</span></>,
      "title_fr": <>Découvrir <br/>les ateliers<span>Glisser sur la scène</span></>,
      "btn_cultural_en": "cultural",
      "btn_cultural_fr": "culturel",
      "btn_therapeutic_en": "therapeutic",
      "btn_therapeutic_fr": "thérapeutique",
      "btn_discover_en": "Discover",
      "btn_discover_fr": "Découvrir",
      "txt_therapeutic_en": <h1>Therapeutic<br/> Workshops</h1>,
      "txt_therapeutic_fr": <h1>Ateliers<br/> Thérapeutiques</h1>,
      "txt_cultural_en": <h1>cultural<br/> Workshops</h1>,
      "txt_cultural_fr": <h1>Ateliers<br/> Culturels</h1>,
    },
    "footerSection": {
      "btn_available_en": "available for projects",
      "btn_available_fr": "disponible pour des projets",
      "btn_center_en": "Dance & Movement",
      "btn_center_fr": "Danse & Mouvement",
    },
    "creditsSection": {
      "title_photography_en": "Photography",
      "title_photography_fr": "Photographie",
      "list_photographers": [
        {
        "name": "Diego Bergandi",
        "url_handle": "https://www.instagram.com/3er_el/"
        },
        {
          "name": "Aline Yatim",
          "url_handle": "https://www.instagram.com/alineyatim/"
        },
        {
          "name": "Josselin Estève",
          "url_handle": "https://www.instagram.com/alineyatim/"
        },
        {
          "name": "Róża Kadi",
          "url_handle": "https://www.instagram.com/rorokadi/?hl=en"
        },
      ],
      "title_developer_en": "Developer and Art Direction",
      "title_developer_fr": "Developer and Art Direction",
      "list_developers": [
        {
        "name": "Lara-Anna Wagner",
        "url_handle": "https://www.instagram.com/laraanna.w"
        }
      ],
     
      "title_mimi_en": "Portfolio for Mytam-Mayo Smith © 2023",
      "title_mimi_fr": "Portfolio for Mytam-Mayo Smith © 2023",
      "img_src": [
        {
          "lighMode": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1701354006/Mytam/Artist/mytam-flower-fields.jpg",
          "darkMode": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1701354006/Mytam/Artist/mytam-flower-fields.jpg",
        }
      ]
    },
    "trainingSection": {
      "training_list_en" : 
       <ul>
        <li>
          <a href="https://www.authentic-flow.com/" target="_blank" rel="noreferrer">Authentic flow</a> 200H training <span>Portugal, July 2023</span>
        </li>
        <li>
          Body-Mind-Centering (BMC) 144H training with <a href="http://www.etrebiendansmoncorps.com/a-propos-de/" target="_blank" rel="noreferrer">Tamara Milla Vigo</a> <span>France, 2022</span>
        </li>
        <li>
          "Fundamentals in Dance-movement therapy", 32H training with <a href="https://irpecor.fr/" target="_blank" rel="noreferrer">Benoit Lesage</a> <span>France, February 2021</span>
        </li>
        <li>
          Dance-rhythm therapy 50H training with <a href="https://www.drt-international.com/" target="_blank" rel="noreferrer">France Schott-Billmann</a> <span>France, October 2021</span>
        </li>
        <li>
          Masters in Interdisciplinary approaches to research and education - Learning Sciences - Center for Interdisciplinary research (now <a href="https://www.learningplanetinstitute.org/en/" target="_blank" rel="noreferrer">Learning Planet Institute)</a> , Université de Paris <span>France, 2019-2021</span>
        </li>
        <ul>
            <li>Masters thesis: "Examining trauma-informed care: University students’ mental health experiences and strategies of mental health care during the COVID-19 pandemic"</li>
            <li>Thesis presented at two conferences:
                <p>"Colloque du Care", <span>Montpellier, France, November 2021</span></p>
                <p>"Colloque Numérique et pandémie. Les enjeux d’éthique un an après", <span>online, July 2021</span></p>
            </li>
            <li><a href="https://www.notion.so/mytam/in-relation-conceptual-guide-ac27f15a95734a70bd9d2a37d95e1ee8" target="_blank" rel="noreferrer">Research project and workshop creation:</a> what is collective trauma, and can we heal it through somatic therapies?</li>
        </ul>
        <li>Contemporary dance practice since childhood, improvisation for +7 years</li>
       </ul>
      ,
      "training_list_fr" : 
      <ul>
        <li>
          Formation <a href="https://www.authentic-flow.com/" target="_blank" rel="noreferrer">Authentic flow</a> 200H <span>Portugal, Juillet 2023</span>
        </li>
        <li>
          Formation Body-Mind-Centering / Anatomie (144h) avec <a href="http://www.etrebiendansmoncorps.com/a-propos-de/" target="_blank" rel="noreferrer">Tamara Milla Vigo</a> <span>France, 2022</span>
        </li>
        <li>
          Stage de formation (32h) en danse-thérapie avec <a href="https://irpecor.fr/" target="_blank" rel="noreferrer">Benoit Lesage</a> "Danse-Thérapie et Structuration Psychocorporelle/Fondamentaux" <span>France, Février 2021</span>
        </li>
        <li>
          Stage de formation (50h) en danse-rythme-thérapie avec <a href="https://www.drt-international.com/" target="_blank" rel="noreferrer">France Schott-Billmann</a> <span>Paris, France, Octobre 2021</span>
        </li>
        <li>
          Master en Approches Interdisciplinaires de la Recherche et l'Enseignement - Learning Sciences, au Centre de Recherche Interdisciplinaire (maintenant <a href="https://www.learningplanetinstitute.org/en/" target="_blank" rel="noreferrer">Learning Planet Institute</a> , Université de Paris <span>France, 2019-2021</span>
        </li>
        <ul>
            <li>Sujet de mémoire: recherche qualitative sur les expériences psychologiques des étudiants universitaires pendant la pandémie.</li>
            <li>Mémoire présentée à deux conférences:
                <p>"Colloque du Care", <span>Montpelper, France, Novembre 2021</span></p>
                <p>"Colloque Numérique et pandémie. Les enjeux d’éthique un an après", <span>visio, Juillet 2021</span></p>
            </li>
            <li><a href="https://www.notion.so/mytam/in-relation-conceptual-guide-ac27f15a95734a70bd9d2a37d95e1ee8" target="_blank" rel="noreferrer">Projet de recherche et création d’atelier :</a> Que sont des traumatismes collectives, et pouvons nous les guérir par les thérapies somatiques ?</li>
        </ul>
        <li>Pratique de danse contemporaine depuis l’enfance et pratique de danse improvisé (+7 ans)</li>
       </ul>
    },
    "somaticPage": {
      "somatic_title_en" : "Somatic Yoga",
      "somatic_title_fr" : "Somatic Yoga",
      "somatic_images" : [],
      "somatic_description_en" : 
        <div>
          <p>In addition to dance-movement workshops, I practice and teach somatic yoga classes. I am currently teaching at Centre Social CEFIA for an all women public.</p>
          <p>Somatic yoga follows the principles “Authentic Flow”, an approach and method founded by <a href="https://satu.yoga/" target="_blank" rel="noreferrer">Satu Tuomela</a>.</p>
          <div className="quote">
            <p>"Authentic flow is a somatic approach to movement through which we listen, taste, see, feel, inquire and learn to embody our bodies as a living, feeling and exploring creative process of becoming.</p> 
            <p>To be/do this, we use breath, sounding, soundscape, fluid-state activation and recognition, a receptive, sensory state of attention, Yoga asana, mantra, intuitive movement, elemental embodiment and more, all informed by non-dual Tantrik philosophy. This allows us to go with what truly is and not what we want/try there to be. We get out of our own way and learn to, not necessarily feel better, but to get better at feeling. We shift our rational and more conventional orientation from linear thinking to a more multi-directional and sensing, feeling Embodied Awakening of the BodyHeartMindSpirit."</p>
            <span><a href="https://www.authentic-flow.com/authenticflow" target="_blank" rel="noreferrer">- Authentic Flow</a></span>
          </div>
        </div>
      ,
      "somatic_description_fr" : 
      <div>
        <p>En plus des ateliers de danse-mouvement, je pratique et donne des cours de yoga somatique. J'enseigne actuellement au Centre Social CEFIA pour un public entièrement féminin.</p>
        <p>Le yoga somatique suit les principes de l'"Authentic Flow", une approche et méthode fondées par <a href="https://satu.yoga/" target="_blank" rel="noreferrer">Satu Tuomela</a>.</p>
        <div className="quote">
          <p>"L'Authentic Flow est une approche somatique du mouvement à travers laquelle nous écoutons, goûtons, voyons, sentons, cherchons et apprenons à incarner notre corps comme un processus créatif vivant, sentant et explorant le devenir.</p> 
          <p>Pour être/faire cela, nous utilisons la respiration, le son, le paysage sonore, l'activation et la reconnaissance de l'état fluide, un état d'attention réceptif et sensoriel, les asanas de yoga, les mantras, le mouvement intuitif, l'incarnation élémentaire et plus encore, le tout informé par la philosophie non-duelle du Tantrik. Cela nous permet d'aller avec ce qui est vraiment et non pas avec ce que nous voulons/essayons qu'il y ait. Nous sortons de notre propre chemin et apprenons, non pas nécessairement à nous sentir mieux, mais à mieux ressentir. Nous passons d'une orientation rationnelle et plus conventionnelle de la pensée linéaire à un éveil incarné du corps, du cœur, du mental et de l'esprit, plus multidirectionnel et sensoriel"</p>
          <span><a href="https://www.authentic-flow.com/authenticflow" target="_blank" rel="noreferrer">- Authentic Flow</a></span>
        </div>
      </div>
    ,
    "btn_home_en": "Go To Home",
    "btn_home_fr": "Aller à l'Accueil",
    "image": {
      "url": "https://res.cloudinary.com/dtvsqvonc/image/upload/v1703000686/Mytam/Somatic/somatic_mimi.jpg",
      "credit_name": "Róża Kadi",
      "credit_url_handle": "https://www.instagram.com/rorokadi/?hl=en"
    } 
    }
  }
}


export default generalDescription;
import './WorkshopCulture.scss';
import { useEffect, useState,useLayoutEffect, useRef} from 'react';
import { gsap } from "gsap";
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import DivImage from '../components/DivImage.js';
import contentData from '../data/generalDescription';
import {isMobile} from 'react-device-detect';
import { Link } from 'react-router-dom';



function WorkshopCulture(props) {
  
  const ref = useRef(null);
  const sliderWrapper = useRef(null);
  const objCultureData = contentData.data.workshopCulturePage;
  const objWorkshopData = contentData.data.workshopOverviewSection;
  
  const [galleryItemWidth, setGalleryItemWidth] = useState();

  useEffect(() => {
    if (isMobile) return;
    props.windowWidth > 1279 ? setGalleryItemWidth(40) : setGalleryItemWidth(65)
  }, [props.windowWidth])
  
  useLayoutEffect(() => {
    if(!isMobile) {
    gsap.registerPlugin(ScrollTrigger);

    let proxy = {skewX: 0}
    let skewSetter = gsap.quickSetter(ref.current, "skewX", "deg")

    let clamp = gsap.utils.clamp(-10,10)

    let ctx = gsap.context(() => {
      let panels = gsap.utils.toArray(".gallery-item");
      gsap.to(panels, {
        x: () => (90 - (galleryItemWidth * panels.length)) + "vw",
        ease: "none",
        scrollTrigger: {
          trigger: sliderWrapper.current,
          start: "top top",
          end: () => "+=" +  (sliderWrapper.current.scrollWidth - window.innerWidth),
          pin: true,
          scrub: 0.1,

          onUpdate: (self) => {
            let skew = clamp(self.getVelocity() / 400);

            // if (Math.abs(skew) > Math.abs(proxy.skew)) {
              proxy.skewX = skew;

              gsap.to(proxy, {
                skewX:0,
                duration: 1,
                ease: "power3",
                overwrite: true,
                onUpdate: () => skewSetter(proxy.skewX),
              })
            // }
          }
        },

       
      });
    });
    
    return () => ctx.revert();
  }
  });

  return (
    <>

    <div className={`WorkshopCulture ${props.lightMode ? "lightMode": "darkMode"}`} id="test" data-scroll-orientation="horizontal" ref={sliderWrapper}>
      <div className="WorkshopCulture--SlideWrapper" ref={ref}>

        <div className="WorkshopCulture--SlideWrapper--Intro gallery-item" >
          <h1>{objCultureData[`title_${props.activeLanguage}`]}</h1>
          <p>{objCultureData[`description_${props.activeLanguage}`]}</p>
          <h2>"{objCultureData.quote}"<span>{objCultureData.quote_credits}</span></h2>
          <Link to="/"><button>{objCultureData[`btn_home_${props.activeLanguage}`]}</button></Link>
        </div>


        {objCultureData.allCultureWorkshops.map((workshop, index) => 
          {
            const images = [];
         
            for (let  image of workshop.images) {
              images.push(
                <div className="WorkshopCulture--SlideWrapper--Projects gallery-item" key={image.src}>
                    <a className="credits" href={image.credit_link} target="_blank" rel="noreferrer">
                      {image.credits}
                    </a>
                  <DivImage imgSrc={image.src}></DivImage>
                </div>
              );
            }
            return (
              <>
              {images}
              <div key={workshop[`title_${props.activeLanguage}`]} className="WorkshopCulture--SlideWrapper--Projects gallery-item description">
                <h3>{workshop[`title_${props.activeLanguage}`]}<br/><span>{workshop[`date_${props.activeLanguage}`]}</span></h3>
                {workshop[`description_${props.activeLanguage}`]}
                {workshop[`subtitle_${props.activeLanguage}`]}
              </div>
            </>
            )
          }
        )}


        <div className="WorkshopCulture--SlideWrapper--Projects gallery-item">
          <div className="gallery-item--circle">
            {objWorkshopData[`txt_therapeutic_${props.activeLanguage}`]}
            <Link to="/workshops/therapeutic"><button>{objWorkshopData[`btn_discover_${props.activeLanguage}`]}</button></Link>
          </div>
        </div>
      </div>
      
    </div>
    </>
  );
}

export default WorkshopCulture;

import './Home.scss';
import {useRef, useState, useLayoutEffect} from 'react';
import Landing from '../sections/Landing.js';
import About from '../sections/About.js';
import WorkshopContainer from '../sections/WorkshopContainer.js';
import Footer from '../sections/Footer.js';
import Gallery from '../sections/Gallery.js';
import Contact from '../sections/Contact.js';
import Performances from '../sections/Performances.js';
import { gsap } from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {isMobile} from 'react-device-detect';

function Home(props) {
  const ref = useRef(null);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  useLayoutEffect(() => {

      if (isMobile) return;
      
      gsap.registerPlugin(ScrollTrigger);

      let home = document.getElementById("Home");
      let workshop = document.getElementsByClassName("WorkshopContainer")[0];
      let ctx = gsap.context(() => {

        let sections = gsap.utils.toArray(home.childNodes);
        sections = sections.splice(0,4)

        sections.forEach((section, i) => {
          ScrollTrigger.create({
            trigger: section,
            start: () => section.offsetHeight < window.innerHeight ? "top top" : "bottom bottom", // if it's shorter than the viewport, we prefer to pin it at the top
            end: "+=" + window.innerHeight * (sections.length - 1 - i),
            pin: true, 
            pinSpacing: false,
            // markers: true,
          });
        });

        ScrollTrigger.create({
          trigger: workshop,
          start: () => workshop.offsetHeight < window.innerHeight ? "top top" : "bottom bottom", // if it's shorter than the viewport, we prefer to pin it at the top
          end: "+=" + window.innerHeight,
          pin: true, 
          pinSpacing: false,
          // markers: true,
        });


      },home);
      return () => ctx.revert();
  });



  function handleMenu() {
    setMenuIsOpen(prevState => !prevState);
  }


  return (
    <>
      <div 
        id="Home" 
        data-scroll-container 
        className={`Home ${props.lightMode ? "lightMode": "darkMode"}`} 
        ref={ref} 
      >

        <Landing 
          mode={props.lightMode}
        />

        <About 
          mode={props.lightMode} 
          activeLanguage={props.activeLanguage} 
          handleModal={props.handleModal} 
          displayModal={props.displayModal}
        />

        <Contact 
          activeLanguage={props.activeLanguage}
        /> 

        <Gallery 
          activeLanguage={props.activeLanguage}
        /> 

        <WorkshopContainer 
          activeLanguage={props.activeLanguage}
        />

        <Performances 
          activeLanguage={props.activeLanguage}
        />

        <Footer 
          handleClickLanguage={props.handleClickLanguage} 
          activeLanguage={props.activeLanguage} 
          handleCredits={props.handleCredits} 
          displayCredits={props.displayCredits}
        />

      </div>
  </>
  );
}

export default Home;

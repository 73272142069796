import './BtnMenu.scss';

function BtnMenu(props) {
  return(
    <div className="BtnMenu link" onClick={props.handleClickMenu}>
      {props.title}
    </div>
  )
}

export default BtnMenu;
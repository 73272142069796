import { useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { gsap } from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

const ScrollToHashElement = () => {
  let location = useLocation();

  let hashElement = useMemo(() => {
    let hash = location.hash;
    const removeHashCharacter = (str) => {
      const result = str.slice(1);
      return result;
    };

    if (hash) {
      let element = document.getElementById(removeHashCharacter(hash));
      return element;
    } else {
      window.scrollTo(0, 0);
      return null;
    }

  }, [location]);


  useEffect(() => {
    if (hashElement) {
      window.scrollTo({
        top: hashElement.getBoundingClientRect().top + window.pageYOffset,
      });
    } 
  }, [hashElement]);

  return null;
};

export default ScrollToHashElement;
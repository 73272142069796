import './Trainings.scss';
import contentData from '../data/generalDescription';


function Trainings(props) {

  const objTrainingsData = contentData.data.trainingSection;
  const objAboutData = contentData.data.aboutSection;
  const objMenuData = contentData.data.mainMenu;

  return(
    <>
    <div 
      className={`Trainings--overlay ${props.displayModal ? 'Show' : ''}`}  
      onClick={props.handleModal}
    >
    </div>

    <div 
      className={`Trainings--content ${props.displayModal ? 'Show' : ''} ${props.mode ? "lightMode" : "darkMode" }`}
    >
      <div className="Trainings-content-wrapper">
        <button 
          className="Close link" 
          onClick={props.handleModal}
        >
          {objMenuData[`btn_close_${props.activeLanguage}`]}
        </button>
        <div className="Trainings--content--list">
          <h1>
            {objAboutData[`btn_training_${props.activeLanguage}`]}
          </h1>
          {objTrainingsData[`training_list_${props.activeLanguage}`]}
        </div>
      </div>
    </div>
    </>
  )
}

export default Trainings;





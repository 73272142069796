import './ModeToggler.scss';

function ModeToggler(props) {

  return(
    <div id="ModeToggler" className={props.mode ? "lightMode": "darkMode"} >
      <label className="ModeToggler--label">
        <input ref={props.modeSwitch} id="toggleswitch" type="checkbox"/>
        <span className="ModeToggler--iconNight"></span>
        <span className="ModeToggler--iconDay"></span>
        <span className="ModeToggler--btn"></span>
      </label>
    </div>
    
  )
}

export default ModeToggler;


